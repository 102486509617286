import React, { Component } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

import { initializeApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'europe-west1');
if (process.env.REACT_APP_VERCEL_ENV === 'development') {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}
const getwords = httpsCallable(functions, 'getwords');
const addword = httpsCallable(functions, 'addword');
const searchwords = httpsCallable(functions, 'searchwords');

class Lists extends Component {
  state = {
    loading: true,
    candidates: [],
    entries: [],
    word: '',
    alertMessage: '',
    alertVariant: 'danger',
  };

  showAlert = (message, variant = 'danger', duration = 5000) => {
    this.setState({
      alertMessage: message,
      alertVariant: variant,
    });
    if (duration > 0) {
      setTimeout(() => {
        this.setState({ alertMessage: '' });
      }, duration);
    }
  };

  handleData = () => {
    getwords({ userId: process.env.REACT_APP_TEMP_USER_ID })
      .then((result) => {
        console.log(result.data);
        const entries = result.data.words;
        this.setState({ entries, loading: false });
      })
      .catch((error) => {
        console.error('Connection failed:', error);
        this.showAlert('Failed to connect. Please try again.', 'danger', 0);
        this.setState({ loading: false });
      });
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ word: value });

    if (value) {
      searchwords({ userId: process.env.REACT_APP_TEMP_USER_ID, search: value })
        .then((result) => {
          console.log(result.data);
          const candidates = result.data.words;
          this.setState({ candidates });
        })
        .catch((error) => {
          console.error('Error calling searchwords:', error);
          this.showAlert('Failed to search words. Please try again.', 'danger');
        });
    } else {
      this.setState({ candidates: [] });
    }
  };

  handleCandidateSelect = (candidate) => {
    addword({
      userId: process.env.REACT_APP_TEMP_USER_ID,
      word: candidate.word,
    })
      .then((result) => {
        console.log(result.data);
      })
      .then(() => {
        this.setState({ word: '', candidates: [] });
        this.handleData();
      })
      .catch((error) => {
        console.error('Error adding word:', error);
        this.showAlert('Failed to add word. Please try again.', 'danger');
      });
  };

  // TODO: Currently not used, retained for possible future explicit word addition
  // handleSubmit = (event) => {
  //   event.preventDefault();

  //   addword({ userId: process.env.REACT_APP_TEMP_USER_ID, word: this.state.word })
  //     .then((result) => {
  //       console.log(result.data);
  //     })
  //     .then(() => {
  //       this.setState({ word: "", candidates: [] });
  //       this.handleData();
  //     });
  // };

  componentDidMount() {
    this.handleData();
  }

  render() {
    const { loading, candidates, entries, alertMessage, alertVariant } = this.state;

    if (loading) {
      return (
        <div className='d-flex justify-content-center mt-5 mb-5'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div>
        {alertMessage && (
          <Alert variant={alertVariant} className='py-2'>
            {alertMessage}
          </Alert>
        )}

        <Form.Control
          type='text'
          name='word'
          placeholder='Type to search...'
          autoComplete='off'
          value={this.state.word}
          onChange={this.handleChange}
        />

        {candidates.length > 0 && (
          <div>
            <p className='candidateList'>Tap a word to add:</p>
            <ListGroup>
              {candidates.map((candidate) =>
                candidate.exists ? (
                  <ListGroup.Item variant='light' key={candidate.word}>
                    {candidate.word}{' '}
                    <Badge pill bg='success'>
                      Added
                    </Badge>
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item
                    action
                    variant='light'
                    key={candidate.word}
                    onClick={() => this.handleCandidateSelect(candidate)}
                  >
                    {candidate.word}
                  </ListGroup.Item>
                ),
              )}
            </ListGroup>
          </div>
        )}

        {entries.length > 0 && (
          <ListGroup className='mt-3'>
            {entries.map((entry) => (
              <ListGroup.Item key={entry._id}>{entry.word}</ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    );
  }
}

export default Lists;
