import React, { Component } from "react";

import Accordion from "react-bootstrap/Accordion";

class Foot extends Component {
  render() {
    return (
      <div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header>
              <div>
                <div>
                  <strong>Version 1.2.0</strong>
                </div>
                <div>
                  <small>What's New</small>
                </div>
              </div>
              {/*   */}
            </Accordion.Header>
            <Accordion.Body>
              Search for words as you type. Click on a word to add it to your list.
              {/* What's new in this version:
              <br />
              <ul>
                <li>Basic styling</li>
                <li>Sorting by alphabetical order</li>
                <li>Validations</li>
              </ul> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
}

export default Foot;
