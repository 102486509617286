import React, { Component } from "react";
import Alert from 'react-bootstrap/Alert';
import "../../App.css";

class Head extends Component {
  render() {
    return (
      <div>
        {/* <Alert variant='info'>
          Sign in is required to use Lexio from April 2025.
        </Alert> */}
        <h1 className="header">Lexio</h1>
      </div>
    )
  }
}

export default Head;
