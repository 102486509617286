import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(
    <App />
);