import Head from "./components/nav/head";
import Foot from "./components/nav/foot";
import Lists from "./components/lists";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Determine the environment from your environment variable
const env = process.env.REACT_APP_VERCEL_ENV;
const envBannerSettings = {
  development: { text: "Development", backgroundColor: "#FFA500" },
  beta: { text: "Beta", backgroundColor: "#007bff" },
  preview: { text: "Preview", backgroundColor: "#6f42c1" },
};

function App() {
  const banner = envBannerSettings[env];

  return (
    <main className="bg-light" style={{ minHeight: "100vh" }}>
      {banner && (
        <div
          style={{
            backgroundColor: banner.backgroundColor,
            color: "#fff",
            textAlign: "center",
            padding: "4px 0",
            fontSize: "0.9rem",
            textTransform: "uppercase",
            fontWeight: 600,
            letterSpacing: "0.05rem",
          }}
        >
          {banner.text}
        </div>
      )}
      <Container className="p-4" style={{ maxWidth: "576px" }}>
        <Row className="mt-3 mb-3">
          <Head />
        </Row>
        <Row className="py-2">
          <Lists />
        </Row>
        <Row className="pt-2">
          <Foot />
        </Row>
      </Container>
    </main>
  );
}

export default App;
